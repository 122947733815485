<template>
    <div style="margin: 0 auto;padding: 10px 30px;"> 
    <Modal :value="show" width="70%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow" style="padding: 10px 30px;">
        <Modal v-if="contract !=null" v-model="showpreview" title="合同内容预览" width="90%" margin-top:10px @on-ok="showpreview=false">
            <div id="previewdiv"  style="margin:0 auto;float:top;margin-top:1px;padding: 5px;width:1000px;background:rgb(233, 238, 234)">
                <div v-html="contract.content" ></div>
            </div>
        </Modal>
      <h1>{{title}}</h1>

    <div id="editdiv" v-if="contract !=null">
        <h3>供应商：</h3>
        <Input v-model="contract.supplier" placeholder="供应商" style="width: 100%;"></Input>

        <h3>合同内容：</h3>
        
        <tinymce ref="editor1" v-model="contract.content" style="margin-top: 20px;height:600px"/>
    </div>
    <div id="btnbar">
        <Button @click="closewindow" type="error" ghost>关闭窗口</Button>
        <Button @click="clear" type="success" ghost >清空内容</Button>
        <Button @click="preview" type="warning"  >预览</Button>
        <Button @click="submmit" type="primary">提交</Button>
    </div>
      
    </Modal>
</div>
</template>
  
  <script>
  import tinymce from '@/components/tinymce.vue'
  export default {
    name: "CghtEdit",
    props: ['cghtdata','show'],
    data(){
        return {
            userlevel:this._self.$root.$store.state.LogonInfo.level,
            companyid:this._self.$root.$store.state.LogonInfo.companyid,
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            showpreview:false,
            contract:null,
            title:''
        }
    },
    components:{
      tinymce
    },
    watch: {
        show(val){
            console.log(val)
            //this.showwin = this.show
            if(val==false){
                this.contract = null
            }

            delete this.$refs.editor1
            console.log(this.contract)
            console.log(this)
        }
    },
    mounted(){
        console.log(this.show)
        //this.showwin = this.show
        if(this.cghtdata ==null){
            this.title ='新增采购合同'
            this.querycontract(null)
        }
        else{
            this.title ='修改采购合同'
            //this.contract = this.cghtdata
            this.querycontract(this.cghtdata.id)
        }
    },
    methods:{
        closewindow(){
            this.$emit('Closewin','')
        },
        // 清空内容
        clear () {
            this.$refs.editor1.clear()
        },
        onEditorChange(event) {
            console.log('onEditorChange')
        },
        querycontract(id){
            let posturl
            let postdata

            if(id == null){
                posturl = "/api/checkdept/contract/getinitdata"
                postdata={
                    companyid: this.companyid,
                }
            }
            else{
                posturl = "/api/checkdept/contract/get"
                postdata={
                    id: id,
                }
            }

            this.$Loading.start();
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Loading.finish();
                    //console.log(res)
                    this.contract = res.data.data
                    console.log(this.contract)
                }
                else{
                    this.$Loading.error();
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                }.bind(this));   
        },
        preview(){
            this.showpreview = true
        },
        submmit(){
            console.log(this.contract)
            let posturl
            if(this.contract.id == null){
                posturl = "/api/checkdept/contract/addnew"
            }
            else{
                posturl = "/api/checkdept/contract/update"
            }
            
            let postdata = JSON.parse(JSON.stringify(this.contract))
            for(var p in postdata){
                if(postdata[p] ==null || postdata[p] == 'null'){
                    delete postdata[p]
                }
            }
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3, closable:true})
                        this.$emit('SubmmitOver','')
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));     
        },
        onshow(v){
            if(v){
                console.log('on show')
                if(this.cghtdata ==null){
                    this.title ='新增采购合同'
                    this.querycontract(null)
                }
                else{
                    this.title ='修改采购合同'
                    this.contract = this.cghtdata
                    this.querycontract(this.contract.id)
                }

                
            }
        },
        oncancel(){
            this.$emit('Closewin','')
        },
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
      .Publish {
        margin: 0 auto;
        padding: 5%;
        width:1300px;
      }
    #editdiv {
      margin:0 auto;
      text-align: left
    }
  
    #editdiv h3{
      margin: 15px auto;
    }
  
    #btnbar{
      margin: 20px auto;
      text-align: center;
    }
  
    #btnbar button{
        width:150px;
        margin:10px;
    }
    #editdiv .ql-editor{
      height:400px;
    }
  
    .notetbl{
        font-size: 10px;
        border: 1px solid lightslategrey;
    }
    .notetbl tr td{
        text-align: left;
        border: 1px solid lightslategrey;
    }
  </style>  