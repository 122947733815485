<template>
    <div >
        <div style="text-align: left;">
            <h2>查询采购合同</h2>
        </div>
        
        <div style="height:40px;width:100%;">
            <Button type="primary"  style="margin-top:3px;float:left" @click="addcght">添加采购合同</Button>
        </div>
        <!-- 账号列表-->
        <div style="padding-top:10px">
            <Table stripe :columns="tblcolumns" :data="tbldata" size="small" border>
            <template slot-scope="{ row }" slot="supplier">
                <strong>{{ row.supplier }}</strong>
            </template>

            <template slot-scope="{ row, index }" slot="action" >
                <div class="opbar">
                    <Button  type="success" size="small" @click="view(index)">查看</Button>
                    <Button v-if="usercategory != 'third'" type="error" size="small" @click="remove(index)">删除</Button>
                    <Button v-if="usercategory != 'third'" type="info" size="small" ghost @click="modify(index)">修改</Button>
                </div>
            </template>
            </Table>
        </div>
        <Modal v-model="showdeleteconfirm" title="删除采购合同" @on-ok="confirmDelete">确定要删除采购合同吗？</Modal>
        <div v-if="showeditcght">
             <cghtEdit v-bind:show="showeditcght" v-bind:cghtdata="this.editcghtdata" @Closewin="onEditClose" @SubmmitOver="onSubmmitOver"/>
        </div>
    </div>
</template>
    
    <script>
    import CghtEdit from './CghtEdit.vue'
    export default {
        name:'cght',
        data () {
                return {
                    showeditcght:false,
                    editcghtdata:null,
                    showdeleteconfirm:false,
                    showuploadwin:false,
                    deleteIndex:null,
                    opindex:null,
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
                    tblcolumns: [
                        {
                            type: 'index',
                            width: 60,
                            align: 'center'
                        },
                        {
                            title: '供应商名称',
                            slot: 'supplier',
                        },         
                        {
                            title: '操作',
                            slot: 'action',
                            align: 'center',
                        }
                        
                    ],
                    tbldata: []
                }
            },
            mounted (){
                this.handleQuery()
            },
            methods: {
                handleQuery() {

                    this.$axios({
                        method:'post',
                        url:"/api/checkdept/contract/list",
                        data:{companyid:this.companyid },
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                            var rspdata = res.data.data
                            this.tbldata =rspdata
                            //this.$Message.success({content:res.data.msg, duration:3})
                            console.log(this)
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this));   
                },
                addcght(){
                    this.editcghtdata = null
                    this.showeditcght = true                
                },
                modify (index) {
                    this.editcghtdata = this.tbldata[index]
                    this.showeditcght = true
                },
                view (index) {
                    let routeUrl = this.$router.resolve({
                        path: "/workdesk/CghtView",
                        query:{contractid:window.btoa(this.tbldata[index].id)}
                    });
                    window.open(routeUrl.href, '_blank');
                },
                confirmDelete(){
                    this.$axios({
                        method:'post',
                        url:"/api/checkdept/contract/delete",
                        data:{id:this.tbldata[this.deleteIndex].id},
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.handleQuery()
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this));      
                },
                remove (index) {
                    this.showdeleteconfirm = true
                    this.deleteIndex = index
                },
                onEditClose(){
                    this.showeditcght =false
                },
                onSubmmitOver(){
                    this.showeditcght =false
                    this.handleQuery()
                },
            },
            components:{
                cghtEdit:CghtEdit
            }
    }
    </script>
    <style scoped>
        .aview {
            margin: 5px;
        }
    
        .opbar {
            display: inline-flex;
            align-items: center;
        }
    
        .opbar button{
            margin:0px 4px;
        }
    
        .ivu-upload {
            display:table-cell;
            align-items: center;
            vertical-align: middle;
            text-align: center;
        }
        .ivu-upload .ivu-upload-select {
            height: 100%;
            align-items: center;
            vertical-align: middle;
            text-align: center;
        }
    
        .ivu-upload .ivu-upload-select button{
            margin:0px 4px;
        }
    </style>